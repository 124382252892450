/* eslint-disable max-params */

import { createSelector } from 'reselect';
import ms from 'milliseconds';

import {
  signedInSelector,
  hasGuestSessionSelector,
} from 'shared/app/bundles/user';
import { appTimeSelector } from 'shared/app/shell';
import { selectedStoreSelector } from 'shared/app/state/selectors/ordering';
import shouldUpdate from 'shared/app/stale-reducers/should-update';

const orderingSelector = (state) => state.ordering;

export const pricingRulesStateSelector = createSelector(
  orderingSelector,
  (ordering) => ordering?.pricingRules
);

export const pricingRulesSelector = createSelector(
  orderingSelector,
  (ordering) => ordering?.pricingRules?.data
);

const pricingRulesCacheTime = ms.minutes(5);

export const shouldFetchPricingRulesSelector = createSelector(
  signedInSelector,
  hasGuestSessionSelector,
  selectedStoreSelector,
  pricingRulesStateSelector,
  appTimeSelector,
  (signedIn, hasGuestSession, selectedStore, pricingRulesState, appTime) => {
    if ((!signedIn && !hasGuestSession) || !selectedStore) return false;

    const updateNeeded = shouldUpdate(pricingRulesState, {
      staleTime: pricingRulesCacheTime,
      now: appTime,
    });

    return updateNeeded;
  }
);
