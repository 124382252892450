// expanded-store
export const SELECT_STORE = 'SELECT_STORE';
export const COLLAPSE_EXPANDED_STORE = 'COLLAPSE_EXPANDED_STORE';

// location
export const FETCH_LOCATIONS = 'FETCH_LOCATIONS';
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';
export const FETCH_LOCATIONS_ERROR = 'FETCH_LOCATIONS_ERROR';

export const SELECT_LOCATION = 'SELECT_LOCATION';
export const FETCH_ERROR = 'FETCH_ERROR';
export const SET_PLACE_NAME = 'SET_PLACE_NAME';
export const PLACE_NAME_NOT_FOUND = 'PLACE_NAME_NOT_FOUND';
export const HIGHLIGHT_LOCATION = 'HIGHLIGHT_LOCATION';
export const UNHIGHLIGHT_LOCATION = 'UNHIGHLIGHT_LOCATION';
export const FETCH_AT_PLACENAME = 'FETCH_AT_PLACENAME';

// favorites
export const FAVORITE_LOCATION_PENDING = 'FAVORITE_LOCATION_PENDING';
export const FAVORITE_LOCATION_SUCCESS = 'FAVORITE_LOCATION_SUCCESS';
export const FAVORITE_LOCATION_FAILURE = 'FAVORITE_LOCATION_FAILURE';

// map
export const SET_MAP_INSTANCE = 'SET_MAP_INSTANCE';
export const MOVE_MAP = 'MOVE_MAP';

// nearest-location
export const REQUEST_NEAREST_LOCATION = 'REQUEST_NEAREST_LOCATION';
export const REQUEST_NEAREST_LOCATION_SUCCESS =
  'REQUEST_NEAREST_LOCATION_SUCCESS';
export const REQUEST_NEAREST_LOCATION_ERROR = 'REQUEST_NEAREST_LOCATION_ERROR';
export const NEAREST_LOCATION_NOT_FOUND = 'NEAREST_LOCATION_NOT_FOUND';

// notifications
export const DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION';

// selected-features
export const SET_FEATURES = 'SET_FEATURES';
export const CLEAR_SELECTED_FEATURES = 'CLEAR_SELECTED_FEATURES';

// selected-store (for ordering/menu integration)
export const SELECT_STORE_MENU = 'SELECT_STORE_MENU';
export const CONFIRM_STORE_MENU = 'CONFIRM_STORE_MENU';
export const CLEAR_STORE_MENU = 'CLEAR_STORE_MENU';
export const CLEAR_STORE_CONFIRMATION = 'CLEAR_STORE_CONFIRMATION';

// filter overlay
export const OPEN_FILTER_OVERLAY = 'OPEN_FILTER_OVERLAY';
export const CLOSE_FILTER_OVERLAY = 'CLOSE_FILTER_OVERLAY';
