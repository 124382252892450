import createSingleResourceReducer from 'shared/app/stale-reducers/single-resource-reducer';

import {
  FETCH_PRICING_RULES,
  FETCH_PRICING_RULES_ERROR,
} from '../actions/types';

const { initialState, reducer } = createSingleResourceReducer({
  startAction: FETCH_PRICING_RULES,
});

export default (state = initialState, action) => {
  state = reducer(state, action);

  switch (action.type) {
    case FETCH_PRICING_RULES_ERROR:
      return {
        ...state,
        data: null,
      };
  }
  return state;
};
