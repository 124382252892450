import PropTypes from 'prop-types';
import React, { forwardRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import Expander from '@starbucks-web/pattern-library/lib/components/expander';
import Icon from '@starbucks-web/pattern-library/lib/components/icon';
import cautionSolid from '@starbucks-web/pattern-library/lib/icons/caution-solid';
import shouldDisplayError from '@starbucks-web/pattern-library/lib/components/form-container/should-display-error';
import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';

import {
  ADD_PAYMENT,
  allOrderPaymentInstrumentsSelector,
  paymentServicesPendingSelector,
} from 'shared/app/bundles/wallet';
import Frap from 'shared/app/components/frap-with-messages';
import FrapContainer from 'shared/app/components/frap-container';
import SharedForm from 'shared/app/components/shared-form';
import { hasSvcCardSelector } from 'shared/app/bundles/svc-cards';
import MonetaryAmountSelect from 'shared/app/components/monetary-amount-select';
import PaymentInstrumentSelect from 'shared/app/components/payment-instrument-select';
import {
  scheduledTimeSlotLabelSelector,
  selectedStoreMopSvcOnly,
  selectedStoreSelector,
  scheduledOrderingEnabledSelector,
} from 'shared/app/state/selectors/ordering';
import {
  IN_STORE_FEATURE_CODE,
  DRIVE_THRU_FEATURE_CODE,
  OUTDOOR_FEATURE_CODE,
  CURBSIDE_FEATURE_CODE,
} from 'shared/universal/pickup-feature-codes';
import { FEATURES } from 'shared/universal/optimizely-keys';
import { Feature } from 'shared/app/components/optimizely';

import PricingSummary from 'ordering/app/components/shared/pricing-summary';
import {
  isTippingEligibleSelector,
  selectedOrderPaymentSelector,
  currentPickupOptionSelector,
} from 'ordering/app/state/selectors';

import OrderPaymentSelect from './order-payment-select';
import TipAmountContainer from './tip-amount-container';
import { TipRemovedDialog, TIP_REMOVED_ID } from './tip-removed-dialog';

import messages from './messages';
import { orderingMessages, paymentMessages } from 'shared/app/messages';
import { InfoReminder } from './info-reminder';
import exclamationCircleIcon from './exclamation-circle-icon';
import clockIcon from './clock-icon';

/* eslint-disable complexity */
/* eslint-disable max-statements */
export const OrderPaymentBottomSheetForm = forwardRef(
  (
    {
      buttonMessage,
      fields,
      inProgress,
      onSubmit,
      pricing,
      reloadAmounts,
      showReload,
      tipAmount,
      setTipAmount,
    },
    ref
  ) => {
    const { formatMessage } = useIntl();
    const { openModal } = useModalContext();

    const isTippingEligible = useSelector(isTippingEligibleSelector);
    const paymentInstruments = useSelector(allOrderPaymentInstrumentsSelector);
    const paymentServicesPending = useSelector(paymentServicesPendingSelector);
    const hasSvcCard = useSelector(hasSvcCardSelector);
    const selectedOrderPayment = useSelector(selectedOrderPaymentSelector);
    const svcPaymentsOnly = useSelector(selectedStoreMopSvcOnly);
    const scheduledOrderingEnabled = useSelector(
      scheduledOrderingEnabledSelector
    );
    const selectedStore = useSelector(selectedStoreSelector);
    const currentPickupOption = useSelector(currentPickupOptionSelector);
    const pricingSummary = pricing?.summary;
    const selectedTimeSlot = useSelector(scheduledTimeSlotLabelSelector);

    useEffect(() => {
      if (
        pricingSummary &&
        !isTippingEligible &&
        selectedOrderPayment?.paymentType !== ADD_PAYMENT &&
        tipAmount > 0
      ) {
        openModal({
          component: TipRemovedDialog,
          ariaLabelledBy: TIP_REMOVED_ID,
        });
      }
    }, [isTippingEligible]);

    const svcSelectHintText = (includeHintText) => {
      if (!includeHintText) {
        return null;
      }
      return (
        <React.Fragment>
          <Icon className="color-yellow" path={cautionSolid} size="18px" />
          <span
            className="color-textBlackSoft pl2 text-xxs"
            data-e2e="insufficient-funds-warning"
          >
            {formatMessage(messages.insufficientFunds)}
          </span>
        </React.Fragment>
      );
    };

    const pickupMethodText = {
      [IN_STORE_FEATURE_CODE]: orderingMessages.orderPickupTypeInStore,
      [DRIVE_THRU_FEATURE_CODE]: orderingMessages.orderPickupTypeDriveThru,
      [OUTDOOR_FEATURE_CODE]: orderingMessages.orderPickupTypeOutdoor,
      [CURBSIDE_FEATURE_CODE]: orderingMessages.orderPickupTypeCurbside,
    };

    return (
      <SharedForm onSubmit={onSubmit} ref={ref}>
        <Feature name={FEATURES.SCHEDULED_ORDERING}>
          {(optimizelyEnabled) => {
            return optimizelyEnabled &&
              scheduledOrderingEnabled &&
              selectedTimeSlot ? (
              <InfoReminder
                colorTheme="green"
                iconPath={clockIcon}
                infoMessage={formatMessage(messages.scheduledOrderingReminder, {
                  pickupMethod: formatMessage(
                    pickupMethodText[currentPickupOption] ||
                      orderingMessages.orderPickupTypeInStore
                  ),
                  timeSlot: selectedTimeSlot,
                  locationSelected: selectedStore?.store?.name,
                })}
              />
            ) : null;
          }}
        </Feature>
        {svcPaymentsOnly ? (
          <InfoReminder
            dataE2e="svc-payment-only-info"
            iconPath={exclamationCircleIcon}
            infoMessage={formatMessage(messages.svcPaymentOnly)}
          />
        ) : null}

        <OrderPaymentSelect
          field={fields?.payment}
          inputProps={{
            hintText: svcSelectHintText(showReload),
            errorMessage: formatMessage(messages.insufficientFunds),
            error: shouldDisplayError(fields.payment),
          }}
        />
        <Expander isExpanded={showReload}>
          <div className="flex mb2">
            <div className="flex-grow pr2">
              <PaymentInstrumentSelect
                label={formatMessage(paymentMessages.reloadSource)}
                paymentInstruments={paymentInstruments}
                {...fields?.reloadSource?.input}
              />
            </div>
            <div className="flex-grow pl2">
              <MonetaryAmountSelect
                amounts={reloadAmounts}
                label={formatMessage(messages.selectLoadAmount)}
                {...fields?.amount?.input}
              />
            </div>
          </div>
        </Expander>
        {isTippingEligible && (
          <TipAmountContainer
            setTipAmount={setTipAmount}
            tipAmount={tipAmount}
          />
        )}
        {pricingSummary && (
          <PricingSummary
            className="ml6 pl3 mt3"
            pricingSummary={pricingSummary}
            tipAmount={tipAmount}
          />
        )}
        {!hasSvcCard && (
          <span className="block pt3 color-red">
            {formatMessage(messages.noPaymentMethods)}
          </span>
        )}
        <FrapContainer
          addSpacingForMultipleChildren={false}
          animated={false}
          relativeAbove="alwaysRelative"
        >
          <Frap
            data-e2e="submitOrderButton"
            loading={inProgress || paymentServicesPending}
            type="submit"
          >
            <FormattedMessage {...buttonMessage} />
          </Frap>
        </FrapContainer>
      </SharedForm>
    );
  }
);

OrderPaymentBottomSheetForm.propTypes = {
  buttonMessage: PropTypes.object,
  className: PropTypes.string,
  fields: PropTypes.object,
  paymentServicesPending: PropTypes.bool,
  pricing: PropTypes.object,
  reloadAmounts: PropTypes.array,
  showReload: PropTypes.bool,
};

export default OrderPaymentBottomSheetForm;
