import { showUnexpectedErrorNotification } from 'shared/app/shell';

import { locationStateSelector } from '../selectors';

import {
  REQUEST_NEAREST_LOCATION,
  REQUEST_NEAREST_LOCATION_SUCCESS,
  REQUEST_NEAREST_LOCATION_ERROR,
  NEAREST_LOCATION_NOT_FOUND,
} from './types';

export const fetchNearestLocation = function () {
  return (dispatch, getState, { apiFetch }) => {
    dispatch({ type: REQUEST_NEAREST_LOCATION });
    const { locationQuery } = locationStateSelector(getState());

    const body = {
      lat: locationQuery?.lat,
      lng: locationQuery?.lng,
      features: locationQuery.features,
    };

    return apiFetch(`/apiproxy/v1/locations/nearest-location`, {
      method: 'post',
      body,
    })
      .then((payload) =>
        dispatch({ type: REQUEST_NEAREST_LOCATION_SUCCESS, payload })
      )
      .catch((err) => {
        dispatch({ type: REQUEST_NEAREST_LOCATION_ERROR });
        if (err.httpStatus === 404) {
          return dispatch({ type: NEAREST_LOCATION_NOT_FOUND });
        }
        dispatch(showUnexpectedErrorNotification());
      });
  };
};
