import createSingleResourceReducer from 'shared/app/stale-reducers/single-resource-reducer';

import {
  FETCH_PRICING_BY_STORE,
  FETCH_PRICING_BY_STORE_ERROR,
} from '../actions/types';

const { initialState, reducer } = createSingleResourceReducer({
  startAction: FETCH_PRICING_BY_STORE,
});

export default (state = initialState, action) => {
  state = reducer(state, action);

  switch (action.type) {
    case FETCH_PRICING_BY_STORE:
      const { storeNumber } = action.payload;
      return {
        ...state,
        storeNumber,
      };
    case FETCH_PRICING_BY_STORE_ERROR:
      return {
        ...state,
        data: null,
      };
  }
  return state;
};
