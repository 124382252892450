import { CLEAR_SELECTED_FEATURES, SET_FEATURES } from '../actions/types';

export default (state = [], action) => {
  switch (action.type) {
    case SET_FEATURES: {
      return [...action.payload];
    }
    case CLEAR_SELECTED_FEATURES: {
      return [];
    }
  }

  return state;
};
