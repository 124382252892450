import { includes } from 'lodash';

import { COLLAPSE_EXPANDED_STORE } from './types';
import { replace, goBack, push } from 'redux-first-history';

export const closeStoreDetailsOnBackButtonPress = () => (dispatch) => {
  dispatch({ type: COLLAPSE_EXPANDED_STORE });
};

export const goBackAfterLocationChange = () => {
  return goBack();
};

export const updateStoreLocatorUrl = ({ pathname, search }) => {
  // paths for store details trigger a PUSH instead of REPLACE to allow
  // for browser back button navigation to return to store locations list
  const url = `${pathname}${search}`;
  if (includes(pathname, '/store/')) {
    return push(url);
  }
  return replace(url);
};
